import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Reverse Hypers 4×10 & Single Arm KB Waiters Walk 4x50ft/arm`}</p>
    <p>{`Low Hang (just below knee) Squat Snatch 4×4\\@80%`}</p>
    <p>{`then, 10:00 AMRAP of:`}</p>
    <p>{`10-Power Snatch (75/55)`}</p>
    <p>{`10-OH Forward Lunges (total)`}</p>
    <p>{`50ft Bear Crawl (25ft down & back)`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`The CrossFit Open starts Thursday, February 21st!  **`}</strong>{`Register at:
Games.CrossFit.com.   We’ll be throwing down each Friday night starting
this Friday the 22nd from 4:30-6:30 so the workouts will be Friday’s wod
each week.  Redo’s will be Saturday’s and Sunday’s after our classes.***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`You can now schedule a massage with Jennifer May, our newest member
and massage therapist! Jennifer is offering a special for the month of
February. You can get a full hour massage for \\$50!! The massage room is
located on the left as soon as you walk into the gym. She works by
appointment only, so email her to schedule.
 `}<a parentName="em" {...{
            "href": "mailto:j.zenmassagetherapy@gmail.com"
          }}>{`j.zenmassagetherapy@gmail.com`}</a>{`.  Sounds like the perfect Valentines
Day gift…`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      